import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

export default () => (
  <>
    <Seo title='Write for us' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Write for Us: Submit a Guest Post"
          subheader='Life and Style Daily is now accepting Contributor Guest Post Writers! '
        />
        <p><span>Are you passionate about Beauty, Skincare, Fashion &amp; Style, Food, Health &amp; Wellness, Home, Lifestyle, Business, and Tech Writing? Then you can become a contributor guest writer for Life and Style Daily!&nbsp;</span></p>
<p><span>We&rsquo;re always looking for new guest authors and we welcome individual bloggers to contribute high-quality guest posts on our blog.</span></p>
<h2><span>As a guest writer for Life and Style Daily, you will be able to&hellip;</span></h2>
<ul>
<li>
<p><span>Inform, inspire and provide valuable information and resources for our readers</span></p>
</li>
<li>
<p><span>Reach an established and influential audience with your message</span></p>
</li>
<li>
<p><span>Have your Article published across ALL of Life and Style Daily's social platforms</span></p>
</li>
</ul>
<h2><span>Guest Post Writing Guidelines</span></h2>
<ul>
<li>
<p><span>All guest posts must be&nbsp;</span><span>original, unpublished</span><span>&nbsp;(We definitely do not accept plagiarized articles or those that are previously published anywhere else, including your own site)</span></p>
</li>
<li>
<p><span>Only submit articles that relate to Beauty, Fashion, Food, Health &amp; Wellness, Home, Lifestyle, Business, and Tech.</span></p>
</li>
<li>
<p><span>All guest posts must be useful, engaging, and relevant to Life and Style Daily's audience.</span></p>
</li>
<li>
<p><span>Guest posts should offer clear advice, takeaways, and how-to tips about general or industry topics.</span></p>
</li>
<li>
<p><span>&nbsp;Content should be of high quality with no spelling or grammatical errors.</span></p>
</li>
<li>
<p><span>All guest bloggers can submit a 2-3 sentence author bio. 1-2 links to the author's website, blog, or other branded online destination may be included.</span></p>
</li>
<li>
<p><span>The length of the blog should be between 800 words and 1000 words.</span></p>
</li>
<li>
<p><span>Formatting: Provide a clear takeaway or learning for readers. Use headings, subheadings, and numbered lists/bullets to structure your writing.</span></p>
</li>
<li>
<p><span>Linking out: Include facts, research, sources, or personal anecdotes to substantiate your insights, information, and arguments with external links (no less than 3).</span></p>
</li>
<li>
<p><span>Internal Linking: Link to&nbsp;</span><a href="https://www.lifestyledaily.com/"><span>lifeandstyledaily.com</span></a><span>&rsquo;s published articles where appropriate.</span></p>
</li>
<li>
<p><span>Unique Angle: Your post should be original and distinct from articles already posted on ponbee.com.</span></p>
</li>
<li>
<p><span>Images/Videos: We encourage authors to include images/videos as part of the submission. If you include images or videos within the article, they must be high quality and come with source citations, only use images/videos for which you have permission or which are in the public domain (and be sure to credit your source).</span></p>
</li>
<li>
<p><span>Short Paragraphs: Use short paragraphs, no more than 3-4 sentences per paragraph.</span></p>
</li>
<li>
<p><span>Article Edits: We reserve the right to edit content you submit but this will primarily be done to correct errors or make the content more readable.</span></p>
</li>
</ul>
<p><span>Authors are responsible for obtaining permission to What We Don&rsquo;t Want: Please don&rsquo;t send us press releases or sales pitches; Fake product reviews, advertorials, etc type of content is not allowed.</span></p>
<p><span>Terms Of Use:</span></p>
<ul>
<li>
<p><span>By submitting an article, you agree to us using it on our website (with any amends we deem suitable) and you will not claim for payment or copyright infringement now or in the future.</span></p>
</li>
<li>
<p><span>When the article is published on our website, it is then owned by us and you are not permitted to re-publish the article without our prior written approval.</span></p>
</li>
</ul>
<hr/>
<p><span>How To Submit:</span><span>&nbsp;Please email&nbsp;</span><a href="mailto:contact.lifeandstyledaily@gmail.com"><span>contact.lifeandstyledaily@gmail.com</span></a><span>&nbsp;for any inquiries or guest post submission.&nbsp;</span></p>
<hr/>
<p><span>Sponsored Blog Posts &amp; Advertising Options: If you want to advertise on our website by putting up a banner ad, publishing a sponsored article, or doing another form of content collaboration, please drop an email regarding the opportunity on&nbsp;</span><a href="mailto:contact.lifeandstyledaily@gmail.com"><span>contact.lifeandstyledaily@gmail.com</span></a><span>.</span></p>
<h1><span>We are always looking for regular contributor writers. Submit your Article Today!</span></h1>
      </Main>
    </Stack>
  </>
)
